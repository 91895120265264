.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h1, .header-link{
  text-align: center;
  -webkit-text-stroke-width: 1px;
  -webkit-text-fill-color: white;
}

h2 {
  text-align: center;
  text-decoration: underline;
}

ul {
  flex-wrap: wrap;
  display:  flex;
  list-style:  none;
}

li {
  width:  100%;
  float: left;
  text-align:  center;
  margin-bottom:  5px;
}

.list {
  width: 33.33%;
  float:  left;
  text-align: center;
  margin-bottom: 30px;
}

button  {
  margin-left: auto;
  margin-right: auto;
  width:  19%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Calendar {
  position: absolute;
  align-self: right;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: auto;
}